.main {
  .title {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.5;
    color: $primary-purple;
  }

  .info {
    display: flex;
    margin-top: 50px;
    align-items: center;
    gap: 40px;

    .background {
      background-color: #73246b;
      border-radius: 5px;
      padding: 10px;
      display: inline-block;

      img {
        width: 27px;
        height: 22px;
      }
    }

    span {
      font-size: 24px;
      font-family: 'GTWalsheimPro-Bold';
      @include rtl-sass-prop(margin-left, margin-right, 25px);
    }
  }
}

@media (max-width: 768px) {
  .main .info {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 400px) {
  .main .info {
    .background {
      padding: 5px;
      img {
        width: 15px;
        height: 10px;
      }
    }

    span {
      font-size: 14px;
    }
  }
}
